import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import  {juniperData, navData, userCanAccessPage, woodpeckerData } from '../config/navData';
import UserContext from '../components/UserContext';
import useCookies from '@js-smart/react-cookie-service';
import { redirectToLogin, decodeJWT } from '../utils/Auth';
import { FlagsProvider } from 'react-feature-flags';
import { Router } from '@reach/router';
import { flags } from '../config/flags';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { JordanContext } from '../components/juniper/Tabs/Campaigns/JordanMode/JordanContext';
import Jordan from '../components/juniper/Tabs/Campaigns/JordanMode/Jordan';
import { getCampaigns } from '../api/juniper';
import Timeout from '../components/sections/Timeout';
import mcNav from '../components/model-controls/mcNav';

const LazyPage = ({ Component, ...props }) => (
  <React.Suspense
    fallback={
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    }
  >
    <Component {...props} />
  </React.Suspense>
);

const Index = ({ location }) => {
  const { check, getCookie } = useCookies();
  const [initials, setInitials] = React.useState('');
  const [user, setUser] = React.useState('');
  const [userGroups, setUserGroups] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [jordanDis, setJordanDis] = useState(false);
  const [campaignData, setCampaignData] = useState();
  const [juniperPage, setJuniperPage] = useState(false);
  const [woodpeckerPage, setWoodpeckerPage] = useState(false);
  const [modelControlsPage, setModelControlsPage] = useState(false);

  useEffect(() => {
    if (jordanDis) {
      getCampaignData();
    }
  }, [jordanDis]);

  useEffect(() => {
    const item = location.pathname
    if (item.includes('juniper')) {
      setJuniperPage(true);
    } else {
      setJuniperPage(false);
    }
    if (item.includes('woodpecker')) {
      setWoodpeckerPage(true);
    } else {
      setWoodpeckerPage(false);
    }if (item.includes('model')) {
      setModelControlsPage(true);
    } else {
      setModelControlsPage(false);
    }
  }, [location]);

  
  const clickHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const idToken = getCookie('id_token');
    if (idToken) {
      const decodedIDToken = decodeJWT(idToken);
      setInitials(decodedIDToken['email'].substring(0, 2).toUpperCase());
      setUser(decodedIDToken['cognito:username']);
      setUserGroups(decodedIDToken['cognito:groups']);
    }
    Timeout();
    alertTime();
  }, []);

  function onRender(id, phase, actualDuration, baseDuration, startTime, commitTime) {
   console.log("id:",id, "phase:",phase, "actualDuration:",actualDuration, "baseDuration:",baseDuration, "startTime:",startTime, "commitTime:",commitTime)
  }
  const getCampaignData = async () => {
    const response = await getCampaigns();
    const validCampaigns = response.data.data.map((item) => {
      return {
        ...item,
        scheduled_date: item.scheduled_date.split(' ')[0],
        upload_date: item.upload_date.split(' ')[0],
      };
    });
    setCampaignData(validCampaigns);
  };

  function alertTime () {
    // Retrieve the alert time from local storage
    const alertTime = new Date(localStorage.getItem('logout_alert'));
  
    // Flag to check if the alert has already been shown
    let alertShown = false;
  
    // Function to check if the alert should be shown
    function alertTimes() {
      // Calculate the timeout value dynamically
      const alertTimeout = alertTime - Date.now();
      
  
      // Check if the alert should be shown (time passed but within the 1-minute window)
      if (alertTimeout <= 0 && alertTimeout >= -60000 && !alertShown) {
        alert('For security purposes, you will be logged out in 5 minutes. Please save your work.');
        document.cookie = "logout_alert=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        
        // Set the flag to true so the alert won't show again
        alertShown = true;
      }
    }
  
    // Run the alert check every 10 seconds
    setInterval(alertTimes, 10000);
  }

  if (typeof window !== `undefined`) {
    if (location.pathname !== '/login' && !(check('access_token') || check('id_token'))) {
      redirectToLogin();
    } else {
      return (

        <UserContext.Provider value={{ userGroups, user, initials, isOpen, clickHandler }}>
          <JordanContext.Provider value={{ jordanDis, setJordanDis, campaignData }}>
            {jordanDis ? (
              <Layout noLayout={jordanDis}>
                {/* This content will bypass the layout */}
                <Jordan />
              </Layout>
            ) : (
              <Layout>
                <FlagsProvider value={flags}>
                  <Router>
   

                    {juniperPage && juniperData?.map((page) => {
                      const Component = React.lazy(() => import(`../components/juniper/Tabs/${page.componentName}/${page.componentName}`));
                      return <LazyPage path={page.path} Component={Component} key={`route ${page.path}`} />;
                    })}
 

                    {woodpeckerPage && woodpeckerData?.map((page) => {
                      const Component = React.lazy(() => import(`../components/woodpecker/Views/${page.componentName}`));
                      return <LazyPage path={page.path} Component={Component} key={`route ${page.path}`} />;
                    })}
                    {modelControlsPage && mcNav?.map((page) => {
                      const Component = React.lazy(() => import(`../components/model-controls/tabs/${page.componentName}`));
                      return <LazyPage path={page.path} Component={Component} key={`route ${page.path}`} />;
                    })}
                    {!juniperPage && !woodpeckerPage && navData
                      .filter((page) => userCanAccessPage(page, userGroups))
                      .map((page) => {
                        const Component = React.lazy(() => import(`../components/sections/${page.componentName}`));
                        return <LazyPage path={page.path} Component={Component} key={`route ${page.path}`} />;
                      })}
                  </Router>
                </FlagsProvider>
              </Layout>
            )}
          </JordanContext.Provider>
        </UserContext.Provider>


      );
    }
  }
};
export default Index;